import { Card, CardProps, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FC } from 'react';

const { Title } = Typography;

type Props = CardProps & {
  children: React.ReactNode;
  title: string;
  color: string;
  extra?: React.ReactNode;
};

export const ColorCard: FC<Props> = ({ children, title, color, ...rest }) => {
  const { xs } = useBreakpoint();

  return (
    <Card
      styles={{ header: { backgroundColor: color } }}
      title={
        <Title level={xs ? 5 : 4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
          {title}
        </Title>
      }
      style={{ minHeight: '100%', width: '100%' }}
      {...rest}>
      {children}
    </Card>
  );
};
