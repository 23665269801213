import { Badge, Button, Col, Drawer, notification, Row, Space, Spin, Tabs, UploadFile } from 'antd';
import { TabsProps } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { shipmentLottie } from 'assets/lottieAssets';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { ShipmentFilters } from 'components/molecules/ShipmentFilters';
import { ShipmentForm } from 'components/molecules/ShipmentForm';
import { ShipmentsTable } from 'components/organisms/ShipmentsTable';
import { FormikProvider, useFormik } from 'formik';
import { DockReceipt, Shipment, ShipmentPayload, shipmentSchema } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import moment from 'moment';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateShipmentMutation, useGetShipmentsQuery } from 'redux/services/linda/lindaApi';
import { setIsShipmentCreated } from 'redux/slices/appSlice';
import { EditShipmentsDrawer } from './EditShipmentsDrawer';
import { LottieButton } from './LottieButton';
import { MobileFiltersShipments } from './MobileFiltersShipments';
import { ShipmentAttachmentUpload } from './ShipmentAttachmentUpload';

type Props = {
  record: DockReceipt;
};

export const ShipmentsDrawer: FC<Props> = ({ record }) => {
  const { xs } = useBreakpoint();
  const [key, setKey] = useState('1');
  const [createShipment] = useCreateShipmentMutation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetShipmentsQuery({ dockReceiptId: record?.id, params: { offset: 0 } });
  const openShipments = data?.data.filter((shipment) => shipment.status !== 'Cancelled' && shipment.status !== 'Completed').length;
  const [visible, setVisible] = useState(false);
  const formik = useFormik<ShipmentPayload>({
    enableReinitialize: true,
    validationSchema: shipmentSchema,
    initialValues: {
      status: 'New',
      receiptType: '',
      processingWarehouse: record?.warehouseId ?? '',
      assignedToUserFullName: record?.assignedToUserFullName ?? '',
      assignedToUserId: record?.assignedToUserId ?? '',
      returnReference: '',
      shipmentDate: moment(record.arrivalDate).format('MM/DD/YYYY'),
      trackingNumbers: [],
      attachmentsToCreate: [],
      attachmentsToDelete: [],
      accountId: '',
      mdsiOrderNumber: '',
      customerReferenceNumber: '',
      customerOrderNumber: '',
      acuityDocumentId: '',
      note: '',
      accountNumber: ''
    },
    onSubmit: async (values) => {
      try {
        const resp = await createShipment({ dockReceiptId: record.id, payload: values }).unwrap();

        setShipment(resp);
        dispatch(setIsShipmentCreated(true));
        setKey('1');
        formik.resetForm();
        notification.success({ message: 'Shipment successfully created' });
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
    formik.resetForm();
    setKey('1');
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Shipments',
      children: (
        <Row justify={'center'} style={{ marginTop: 16 }}>
          <ShipmentsTable dockRecord={record} />
          {shipment && <EditShipmentsDrawer setShipment={setShipment} record={shipment} isCreate dockReceiptId={record.id} />}
        </Row>
      )
    },
    {
      key: '2',
      label: 'Create Shipment',

      children: (
        <Row style={{ marginTop: 16, width: '100%' }}>
          <Col span={24}>
            <Spin style={{ width: '100%' }} spinning={formik.isSubmitting} indicator={<LoaderWithMessage loadingMessage="Creating shipment..." />}>
              <ShipmentForm isCreate recordId={record.partitionKeyLevel2} />
              <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
                <Row gutter={[8, 0]} style={{ width: '100%' }}>
                  <Col span={xs ? 24 : 24}>
                    <ShipmentAttachmentUpload isCreate fileListState={fileList} setFileList={setFileList} />
                  </Col>
                  {xs && <Col style={{ margin: 0, marginBottom: 8, marginTop: 8 }} />}
                </Row>
              </Space>
            </Spin>
          </Col>
        </Row>
      )
    }
  ];

  const handleOk = (): void => {
    formik.submitForm();
  };

  return (
    <FormikProvider value={formik}>
      <Badge color="green" size="small" count={openShipments}>
        <LottieButton tooltip={!xs ? 'Shipments' : undefined} animationData={shipmentLottie} borderColor="#52CC99" hoverColor="#DDFFEE" onClick={showDrawer} scale={1.5} />
      </Badge>
      <Drawer
        destroyOnClose
        footer={
          <Row justify={'end'} style={{ width: '100%' }} align={'bottom'}>
            <Space>
              <Button onClick={onClose}>{'Exit'}</Button>
              {key === '2' && (
                <Button type={'primary'} onClick={handleOk}>
                  Create
                </Button>
              )}
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        height={'90%'}
        width={xs ? '100%' : '75%'}
        open={visible}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard title={`Shipments for Load ${record.loadId}`} color={toRgba(colors.robinEggBlue, 0.4)} extra={xs && <MobileFiltersShipments />}>
          {!xs && <ShipmentFilters isDrawer />}
          <Tabs
            onChange={(activeKey): void => {
              setKey(activeKey);
              setFileList([]);
            }}
            activeKey={key}
            defaultActiveKey="1"
            items={items}
          />
        </ColorCard>
      </Drawer>
    </FormikProvider>
  );
};
