import * as yup from 'yup';
import { DivisionShort } from './Division';

export type Load = {
  carrierId: string;
  warehouseId: string;
  assignedToUserId: string;
  arrivalDate: string;
  assignedToUserFullName: string;
  isActive: boolean;
  status?: string;
  trackingNumbers: string[];
};

export type DockReceipt = {
  assignedTrackingNumbers: string[];
  arrivalDate: string | null;
  assignedToUserFullName: string;
  assignedToUserId: string;
  carrierId: string;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: DivisionShort;
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  loadId: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKeyLevel1: string;
  partitionKeyLevel1Description: string;
  partitionKeyLevel2: string;
  partitionKeyLevel2Description: string;
  partitionKeyLevel3: string;
  partitionKeyLevel3Description: string;
  shipments: unknown[];
  status: string;
  masterTrackingNumbers: string[];
  unassignedTrackingNumbers: string[];
  warehouseId: string;
};

export type ShipmentPayload = {
  assignedToUserId: string;
  accountId: string;
  mdsiOrderNumber: string;
  customerReferenceNumber: string;
  customerOrderNumber: string;
  acuityDocumentId: string;
  assignedToUserFullName: string;
  processingWarehouse: string;
  receiptType: string;
  returnReference: string;
  status: string;
  shipmentDate: string;
  trackingNumbers: string[];
  attachmentsToCreate: ShipmentAttachment[];
  attachmentsToDelete: string[];
  note: string;
  accountNumber?: string;
};

export type ShipmentAttachment = {
  fileBase64: string;
  fileName: string;
};

export type Shipment = {
  accountId: string;
  acuityDocumentId: string;
  assignedToUserFullName: string;
  assignedToUserId: string;
  attachments: ShipmentAttachmentsResponse[];
  containers: any[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  customerOrderNumber: string;
  customerReferenceNumber: string;
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  loadId: string;
  mdsiOrderNumber: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  notes: ShipmentNote[];
  partitionKeyLevel1: string;
  partitionKeyLevel1Description: string;
  partitionKeyLevel2: string;
  partitionKeyLevel2Description: string;
  processingWarehouse: string;
  receiptType: string;
  returnReference: string;
  shipmentDate: string;
  shipmentId: string;
  status: string;
  trackingNumbers: string[];
  accountNumber: string | null;
};

export type ShipmentNote = {
  note: string;
  userId: string;
  userFullName: string;
  createdTimeStamp: string;
  id: string;
};

export type ShipmentAttachmentsResponse = {
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  id: string;
  name: string;
  partitionKeyLevel1: string;
  partitionKeyLevel1Description: string;
  partitionKeyLevel2: string;
  partitionKeyLevel2Description: string;
  partitionKeyLevel3: string;
  partitionKeyLevel3Description: string;
  size: number;
  url: string;
};

export type DockReceiptParams = {
  includeInactiveData?: boolean;
  includeDeletedData?: boolean;
  returnInactiveDataOnly?: boolean;
  returnDeletedDataOnly?: boolean;
  trackingNumberContains?: string;
  loadIdContains?: string;
  warehouseIdContains?: string;
  carrierIdContains?: string;
  statuses?: string;
  orderByDirection?: string;
  orderByField?: string;
  overrideSkipTake?: string;
  offset: number;
  take?: number;
  startDate?: string;
  endDate?: string;
};
export type ShipmentParams = {
  includeInactiveData?: boolean;
  includeDeletedData?: boolean;
  returnInactiveDataOnly?: boolean;
  returnDeletedDataOnly?: boolean;
  statuses?: string;
  customerIdContains?: string;
  receiptType?: string;
  warehouseIdContains?: string;
  d365PurchaseOrderNumber?: string;
  startDate?: string;
  endDate?: string;
  orderByDirection?: string;
  orderByField?: string;
  overrideSkipTake?: string;
  offset: number;
  take?: number;
  returnReference?: string;
  vendorIdContains?: string;
  trackingNumberContains?: string;
  accountIdContains?: string;
  accountNumberContains?: string;
  returnReferenceContains?: string;
  mdsiOrderNumberContains?: string;
};

export const loadSchema = yup.object().shape({
  carrierId: yup.string().required('Carrier ID is required'),
  warehouseId: yup.string().required('Warehouse ID is required'),
  assignedToUserId: yup.string().required('Assigned To User ID is required'),
  arrivalDate: yup.string().required('Arrival Date is required'),
  assignedToUserFullName: yup.string().required('Assigned To User Full Name is required'),
  trackingNumbers: yup.array().of(yup.string().required('Tracking Number is required')).required('Tracking Numbers are required'),
  isActive: yup.boolean().required('Is Active is required'),
  status: yup.string().nullable()
});

export type ReturnRef = {
  accountId: string;
  acuityDocumentId: string;
  customerOrderNumber: string;
  customerReferenceNumber: string;
  mdsiOrderNumber: string;
};

export const shipmentSchema = yup.object().shape({
  assignedToUserId: yup.string().required('Assigned To User ID is required'),
  assignedToUserFullName: yup.string().required('Assigned To User Full Name is required'),
  processingWarehouse: yup.string().required('Processing Warehouse is required'),
  receiptType: yup.string().required('Receipt Type is required'),
  returnReference: yup.string().required('Return Reference is required'),
  shipmentDate: yup.string().required('Shipment Date is required'),
  status: yup.string().required('Status is required'),
  trackingNumbers: yup.array().of(yup.string().required('Tracking Number is required')).required('Tracking Numbers are required').min(1, 'At least one tracking number is required')
});
