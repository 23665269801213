import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputProps, InputRef, Modal, notification, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Field, FieldProps, useFormikContext } from 'formik';
import { ReturnRef, ShipmentPayload } from 'models/DockReceipt';
import { FC, useEffect, useRef, useState } from 'react';
import { useGetReturnRefSearchQuery } from 'redux/services/linda/lindaApi';
import { useDebouncedCallback } from 'use-debounce';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReturnReferenceSearch: FC<Props> = ({ fieldName, placeholder, type, label, setLoadingState, ...rest }) => {
  const { handleSubmit, values, setValues } = useFormikContext<ShipmentPayload>();
  const { xs } = useBreakpoint();

  const [params, setParams] = useState({ referenceValue: '', referenceType: '' });
  const { data, isLoading, isFetching } = useGetReturnRefSearchQuery({ params }, { skip: !params.referenceValue || !params.referenceType });
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState<ReturnRef | undefined>(undefined);

  const inputRef = useRef<InputRef>(null);

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setLoadingState(isLoading || isFetching);
    if (!isLoading && !isFetching && data?.length === 1) {
      setValues((prev) => ({
        ...prev,
        accountId: data[0]?.accountId as string,
        mdsiOrderNumber: data[0]?.mdsiOrderNumber as string,
        customerReferenceNumber: data[0]?.customerReferenceNumber as string,
        acuityDocumentId: data[0]?.acuityDocumentId as string
      }));
    }
    if (!isLoading && !isFetching && !data?.length && params.referenceValue && params.referenceType) {
      notification.error({ message: 'No results found' });
      setResult(undefined);
      setValues((prev) => ({
        ...prev,
        accountId: '',
        mdsiOrderNumber: '',
        customerReferenceNumber: '',
        acuityDocumentId: ''
      }));
    }
    if (!isLoading && !isFetching && (data?.length ?? 0) > 1) {
      setOpen(true);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (!values.returnReference) {
      setResult(undefined);
    }
  }, [values]);

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value) {
      setValues((prev) => ({
        ...prev,
        accountId: '',
        mdsiOrderNumber: '',
        customerReferenceNumber: ''
      }));

      return;
    }

    setParams({ referenceValue: value, referenceType: values.receiptType });
  }, 500);

  return (
    <>
      <Field name={fieldName} placeholder={placeholder}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          return (
            <div>
              <FormLabel label={label} />
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  ref={inputRef}
                  onKeyDown={handleEnter}
                  onFocus={(): void => {
                    setFieldTouched(fieldName, false);
                  }}
                  {...field}
                  onChange={(e) => {
                    setFieldValue(fieldName, e.target.value);
                    handleChange(e);
                  }}
                  type={type}
                  disabled={!values.receiptType}
                  {...rest}
                />
                {data && data.length > 1 && values.returnReference && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                    icon={<InfoCircleOutlined />}
                  />
                )}
              </Space.Compact>
              <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
            </div>
          );
        }}
      </Field>
      <Modal
        title="Please select the correct result:"
        open={open}
        afterClose={(): void => inputRef.current?.blur()}
        onCancel={(): void => setOpen(false)}
        onOk={(): void => {
          setValues((prev) => ({
            ...prev,
            accountId: result?.accountId as string,
            mdsiOrderNumber: result?.mdsiOrderNumber as string,
            customerReferenceNumber: result?.customerReferenceNumber as string,
            acuityDocumentId: result?.acuityDocumentId as string
          }));

          setOpen(false);
        }}>
        <div style={{ marginTop: 16 }}>
          {data?.map((item, idx) => (
            <Row key={idx}>
              <Space key={idx} style={{ marginBottom: 8 }}>
                <Checkbox
                  checked={result === item}
                  disabled={result && result !== item}
                  onChange={(checked): void => {
                    if (checked.target.checked) {
                      setResult(item);
                    } else setResult(undefined);
                  }}
                />
                <Typography.Text style={{ marginLeft: 8 }}>
                  {item.accountId} - {item.mdsiOrderNumber}
                </Typography.Text>
              </Space>
            </Row>
          ))}
        </div>
      </Modal>
    </>
  );
};
